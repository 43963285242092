import React, {Component} from 'react'

class YelpURL extends Component {
    render() {
        return (
            <a href={this.props.url} target="_blank" rel="noopener noreferrer" className="yelpURL">
                <span style={{color: "red"}}><i className="fa fa-yelp" aria-hidden="true" /></span> View on Yelp
            </a>
        )
    }
}

export default YelpURL