import React, {Component} from 'react'
import SearchBar from "./SearchBar";
import RestaurantRow from "../Restaurants/RestaurantRow";
import GoogleMaps from "./GoogleMaps";
import {cuisineEmojiMap} from "../cuisineEmojiMap";

class Homepage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            restaurants: this.props.restaurants,
            activeMarker: {},
            emojiMap: null
        }
    }

    reformatEmojiMap = (emojiMap) => {
        let reformattedMap = {}

        if (emojiMap.length > 0){
            for (let i=0; i<emojiMap.length; i++){
                reformattedMap[emojiMap[i]['cuisine']] = emojiMap[i]['emoji']
            }
        }

        return reformattedMap
    }

    componentDidMount(){
        const error = () => this.setState({ emojiMap: cuisineEmojiMap })

        if (this.state.emojiMap === null){
            fetch("https://api.apispreadsheets.com/data/15219/?accessKey=37e3c687f16c3c3b2753e380923470ac&secretKey=fbd166dc87f0103bee921c055b76aa27").then(res=>{
                if(res.status === 200){
                    res.json().then(data => {
                        this.setState({ emojiMap: this.reformatEmojiMap(data['data'])})
                    }).catch(err => error())
                } else {
                    error()
                }
            }).catch(err => error())
        }
    }

    updateRestaurants = (updatedRestaurants) => {
        this.setState({ restaurants: updatedRestaurants })
    }

    updateActiveMarker = activeMarker => this.setState({ activeMarker: activeMarker })

    clearSearch = () => this.setState({ restaurants: this.props.restaurants, activeMarker: {} })

    render() {
        return (
            <div className="homepageIndex">
                <div className="row">
                    <div className="col-sm-5">
                        <GoogleMaps activeMarker={this.state.activeMarker}
                                        updateRestaurants={this.updateRestaurants}
                                        updateActiveMarker={this.updateActiveMarker}
                                        restaurants={this.props.restaurants}
                                        cityInfo={this.props.cityInfo}
                        />
                        {/*<iframe title="Map" src="https://www.google.com/maps/d/embed?mid=1TonjqcmDAfZVh834XdReSWH2RJBYYFHX" width="100%" height="480">*/}
                        {/**/}
                        {/*</iframe>*/}
                    </div>
                    <div className="col-sm-1" />
                    <div className="col-sm-6">
                        {
                            'position' in this.state.activeMarker ?
                                <button className="seeAllButton" onClick={this.clearSearch}><i className="fa fa-undo" aria-hidden="true" /> See All Restaurants</button> :
                                <div className="searchBar">
                                    <SearchBar allRestaurants={this.props.restaurants} currRestaurants={this.state.restaurants} updateRestaurants={this.updateRestaurants}/>
                                </div>
                        }
                        <p className="showingBar">Showing <b>{this.state.restaurants.length}</b> out of <b>{this.props.restaurants.length}</b> Restaurants</p>
                        {
                            this.state.restaurants.length > 0 ? this.state.restaurants.map((x, idx) => <RestaurantRow key={idx} emojiMap={this.state.emojiMap}
                                info={x}/>) : <h2>No restaurants match your search :(</h2>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Homepage