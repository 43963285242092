import React, {Component} from 'react'
import Loader from "../Loader";

class GettingRestaurants extends Component {
    render() {
        return (
            <div style={{textAlign: "center", marginTop: "2%"}}>
                <Loader/>
                <p>Getting list of restaurants...</p>
            </div>
        )
    }
}

export default GettingRestaurants