import React, {Component} from 'react'
import './App.css';
import Loader from "./Loader";
import CitySelectionToolbar from "./CitySelection/CitySelectionToolbar";
import GettingRestaurants from "./CityHomepages/GettingRestaurants";
import ErrorRestaurants from "./CityHomepages/ErrorRestaurants";
import Homepage from "./CityHomepages/Homepage";
import {
    BrowserView,
    MobileView
} from "react-device-detect";
import MobileCitySelection from "./CitySelection/MobileCitySelection";


class CityIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cityInfo: null,
            allCities: null,
            restaurants: null
        }
    }

    componentDidMount(){
        const errFetch = () => this.setState({ allCities: false })

        fetch("https://api.apispreadsheets.com/data/14990/?accessKey=e08291295756915c51a674ab6cea33e6&secretKey=743191228e1ad7db1faf213132e3835c").then(res=>{
            if (res.status === 200){
                // SUCCESS
                res.json().then(data=>{
                    const allCities = data['data']

                    const url = window.location.href;
                    let param = "?cityName="

                    if (url.includes(param)){
                        let cityIdx = -1

                        const formatStr = (str) => str.toLowerCase().replaceAll(" ", "-");

                        try{
                            let cityNameFull = formatStr(url.substring(url.indexOf(param) + param.length, url.length).replaceAll("/", ""));

                            for (let i=0; i<allCities.length; i++){
                                if (formatStr(allCities[i]['name']) === cityNameFull){
                                    cityIdx = i;
                                    break
                                }
                            }
                        } catch (e) {
                            console.log(e)
                            cityIdx = -1
                        }

                        if (cityIdx === -1 ){
                            this.setState({ allCities: allCities })
                        } else {
                            this.setState({ allCities: allCities }, () => this.changeCityInfo(allCities[cityIdx]))
                        }
                    } else {
                        this.setState({ allCities: allCities })
                    }

                }).catch(err => errFetch())
            }
            else{
                errFetch()
            }
        }).catch(err => errFetch())
    }



    changeCityInfo = (cityInfo) => {
        this.setState({ cityInfo: cityInfo, restaurants: null })

        const errFetch = () => this.setState({ restaurants: false })

        fetch(cityInfo['api_url']).then(res=>{
            if (res.status === 200){
                // SUCCESS
                res.json().then(data=>{
                    this.setState({ restaurants: data['data'] })

                }).catch(err => errFetch())
            }
            else{
                errFetch()
            }
        }).catch(err => errFetch())

    }

    render() {
        let body = null;
        let homepageBody = null;

        if (this.state.allCities === null){
            body =  <div style={{textAlign: "center", paddingTop: "2%"}}>
                        <Loader/>
                        <p>Getting list of cities...</p>
                    </div>
        } else if (this.state.allCities === false){
            body = <div style={{textAlign: "center", paddingTop: "2%"}}>
                        <h2>There was an error getting the cities :( Please refresh and try again.</h2>
                    </div>
        } else {
            if (this.state.cityInfo !== null){
                if (this.state.restaurants === null){
                    homepageBody = <GettingRestaurants/>
                } else if (this.state.restaurants === false){
                    homepageBody = <ErrorRestaurants/>
                } else {
                    homepageBody = <Homepage cityInfo={this.state.cityInfo} restaurants={this.state.restaurants}/>
                }
            }
            body = <div>
                <div style={{textAlign: "center", fontSize: "36px", fontWeight: "700", paddingTop: "5%"}}>Jiklu</div>
                <p style={{textAlign: 'center'}}>Choose a city to see highly recommended restaurants by food bloggers & critics</p>
                <BrowserView>
                    <CitySelectionToolbar changeCityInfo={this.changeCityInfo} currCityInfo={this.state.cityInfo} allCities={this.state.allCities} />
                </BrowserView>
                <MobileView>
                    <MobileCitySelection changeCityInfo={this.changeCityInfo} currCityInfo={this.state.cityInfo} allCities={this.state.allCities}/>
                    {this.state.cityInfo !== null ? <h2 style={{padding: "10px 0 10px 0", textAlign: "center"}}>
                        <img src={this.state.cityInfo['pic']}
                             style={{width: "10%", height: "10%", borderRadius: "50%"}}
                             alt={this.state.cityInfo['name'] + "Logo"} /><span style={{paddingLeft: "15px"}}>{this.state.cityInfo['name']}</span></h2> : null}
                </MobileView>
                {homepageBody}
            </div>
        }

        return (
            <div className="App">
                {body}
            </div>
        )
    }
}

export default CityIndex