import React, {Component} from 'react'

class RestaurantAddress extends Component {
    copy = () => {
        navigator.clipboard.writeText(this.props.address)
        alert("Copied Address!")
    }

    render() {
        return (
            <div className="restaurantAddress">
                <i className="fa fa-clipboard" style={{color: "green", cursor: "pointer"}} aria-hidden="true" onClick={this.copy}/> {this.props.address}
            </div>
        )
    }
}

export default RestaurantAddress