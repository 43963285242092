import React, {Component} from 'react'
import CuisineTag from "./CuisineTag";
import YelpURL from "./YelpURL";
import RestaurantURL from "./RestaurantURL";
import RestaurantAddress from "./RestaurantAddress";
import RestaurantDetails from "./RestaurantDetails";

class RestaurantRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDetails: false
        }
    }

    toggleDetails = () => {
        const currShow = JSON.parse(JSON.stringify(this.state.showDetails))

        this.setState({
            showDetails: !currShow
        })
    }

    render() {
        return (
            <div className="restaurantRow">
                <div className="row">
                    <div className="col-sm-8">
                        <h4 style={{color: "black", marginBottom: "0px"}}>{this.props.info['Name']} <small style={{color: "gray"}}>{this.props.info['Price']}</small></h4>
                        <RestaurantAddress address={this.props.info['Address']}/>
                        {this.props.info['Cuisine'].split(",").map((x, idx) => <CuisineTag emojiMap={this.props.emojiMap} name={x.trim()} key={idx}/>)}
                    </div>
                    <div className="col-sm-4">
                        <div className="rowURL">
                            {
                                this.props.info['Yelp URL'].trim() === "" ? null : <YelpURL url={this.props.info['Yelp URL']}/>
                            }
                        </div>
                        <div className="rowURL">
                            {this.props.info['URL'].trim() !== "" ? <RestaurantURL url={this.props.info['URL'].trim()}/> : null}
                        </div>
                    </div>
                </div>
                <hr />
                <h6 className="toggleDetailsButton" onClick={this.toggleDetails}><i className={this.state.showDetails ? "fa fa-minus-circle" : "fa fa-plus-circle"} aria-hidden="true" /> {this.state.showDetails ? "Hide" : "See More Info"}</h6>
                {
                    this.state.showDetails ? <RestaurantDetails info={this.props.info} /> : null
                }
            </div>
        )
    }
}

export default RestaurantRow