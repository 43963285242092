import React, {Component} from 'react'
import FoundFromList from "./FoundFromList";

class RestaurantDetails extends Component {
    render() {

        return (
            <div>
                <h6>📝 Editor's note <b>{this.props.info['Notes']}</b></h6>
                <h6>🥘 Notable dishes <b>{this.props.info['Dishes']}</b></h6>
                <h6>🥡 Vibe <b>{this.props.info['Vibe']}</b> </h6>
                <div style={{backgroundColor: "white", padding: "10px"}}>
                    <h6><i>Appears in</i></h6>
                    {this.props.info['FoundFrom1'].trim() !== "" ? <FoundFromList foundFrom={this.props.info['FoundFrom1']} url={this.props.info['FoundFromURL1']}/> : null}
                    {this.props.info['FoundFrom2'].trim() !== "" ? <FoundFromList foundFrom={this.props.info['FoundFrom2']} url={this.props.info['FoundFromURL2']}/> : null}
                    {this.props.info['FoundFrom3'].trim() !== "" ? <FoundFromList foundFrom={this.props.info['FoundFrom3']} url={this.props.info['FoundFromURL3']}/> : null}
                    {this.props.info['FoundFrom4'].trim() !== "" ? <FoundFromList foundFrom={this.props.info['FoundFrom4']} url={this.props.info['FoundFromURL4']}/> : null}
                </div>
            </div>
        )
    }
}

export default RestaurantDetails