import React, {Component} from 'react'
import Select from 'react-select'

class MobileCitySelection extends Component {
    changeCityInfo = (cityName) => {
        for (let i=0; i<this.props.allCities.length; i++){
            if (this.props.allCities[i]['name'] === cityName['value']){
                this.props.changeCityInfo(this.props.allCities[i])
                break
            }
        }
    }

    getDropdownOptions = () => {
        let options = [];

        for (let i=0; i < this.props.allCities.length; i++){
            const cityInfo = this.props.allCities[i];

            options.push({
                value: cityInfo['name'],
                label: <span><img src={cityInfo['pic']}
                            style={{width: "10%", height: "10%", borderRadius: "50%"}}
                            alt={cityInfo['name'] + "Logo"} />
                <span style={{fontWeight: 'bold', color: 'black', paddingLeft: "15px"}}>{cityInfo['name']}</span></span>
            })
        }

        return options
    }

    render() {
        if (this.props.allCities.length === 0){
            return (
                null
            )
        } else{
            const options = this.getDropdownOptions()

            return (
                <div style={{margin: "0 10px 0 10px"}}>
                    <Select
                        value={this.props.currCityInfo === null ? null : this.props.currCityInfo['name']}
                        onChange={this.changeCityInfo}
                        options={options}
                    />
                </div>
            )
        }

    }
}

export default MobileCitySelection