import React, {Component} from 'react'
import CitySelectionBtn from "./CitySelectionBtn";

class CitySelectionToolbar extends Component {
    changeCityInfo = (cityInfo) => this.props.changeCityInfo(cityInfo)

    render() {
        if (this.props.allCities.length === 0){
            return (
                null
            )
        }
        else if (this.props.allCities.length === 1){
            return (
                <div className="row">
                     <div className="col-sm-12">
                         <CitySelectionBtn changeCityInfo={this.changeCityInfo}
                                           currCityInfo={this.props.currCityInfo}
                                           info={this.props.allCities[0]}/>
                     </div>
                </div>
            )
        } else if (this.props.allCities.length === 2){
            return (
                <div className="row">
                    <div className="col-sm-6">
                        <CitySelectionBtn changeCityInfo={this.changeCityInfo}
                                          currCityInfo={this.props.currCityInfo}
                                          info={this.props.allCities[0]}/>
                    </div>
                    <div className="col-sm-6">
                        <CitySelectionBtn changeCityInfo={this.changeCityInfo}
                                          currCityInfo={this.props.currCityInfo}
                                          info={this.props.allCities[1]}/>
                    </div>
                </div>
            )
        } else if (this.props.allCities.length === 3){
            return (
                <div className="row">
                    <div className="col-sm-4">
                        <CitySelectionBtn changeCityInfo={this.changeCityInfo}
                                          currCityInfo={this.props.currCityInfo}
                                          info={this.props.allCities[0]}/>
                    </div>
                    <div className="col-sm-4">
                        <CitySelectionBtn changeCityInfo={this.changeCityInfo}
                                          currCityInfo={this.props.currCityInfo}
                                          info={this.props.allCities[1]}/>
                    </div>
                    <div className="col-sm-4">
                        <CitySelectionBtn changeCityInfo={this.changeCityInfo}
                                          currCityInfo={this.props.currCityInfo}
                                          info={this.props.allCities[2]}/>
                    </div>
                </div>
            )
        } else {
            let rowNum = Math.ceil(this.props.allCities.length / 4);

            let rows = [];
            for (let i=0; i<rowNum; i++){
                let cityInfoSubArray = this.props.allCities.slice(i * 4, (i+1) * 4)
                let rowClassName = i === 0 ? "row" : "row cityToolbarRow";

                if (cityInfoSubArray.length === 4){
                    rows.push(
                        <div className={rowClassName}>
                            <div className="col-sm-3">
                                <CitySelectionBtn changeCityInfo={this.changeCityInfo}
                                                  currCityInfo={this.props.currCityInfo}
                                                  info={cityInfoSubArray[0]}/>
                            </div>
                            <div className="col-sm-3">
                                <CitySelectionBtn changeCityInfo={this.changeCityInfo}
                                                  currCityInfo={this.props.currCityInfo}
                                                  info={cityInfoSubArray[1]}/>
                            </div>
                            <div className="col-sm-3">
                                <CitySelectionBtn changeCityInfo={this.changeCityInfo}
                                                  currCityInfo={this.props.currCityInfo}
                                                  info={cityInfoSubArray[2]}/>
                            </div>
                            <div className="col-sm-3">
                                <CitySelectionBtn changeCityInfo={this.changeCityInfo}
                                                  currCityInfo={this.props.currCityInfo}
                                                  info={cityInfoSubArray[3]}/>
                            </div>
                        </div>
                    )
                } else if (cityInfoSubArray.length === 3){
                    rows.push(
                        <div className={rowClassName}>
                            <div className="col-sm-3">
                                <CitySelectionBtn changeCityInfo={this.changeCityInfo}
                                                  currCityInfo={this.props.currCityInfo}
                                                  info={cityInfoSubArray[0]}/>
                            </div>
                            <div className="col-sm-3">
                                <CitySelectionBtn changeCityInfo={this.changeCityInfo}
                                                  currCityInfo={this.props.currCityInfo}
                                                  info={cityInfoSubArray[1]}/>
                            </div>
                            <div className="col-sm-3">
                                <CitySelectionBtn changeCityInfo={this.changeCityInfo}
                                                  currCityInfo={this.props.currCityInfo}
                                                  info={cityInfoSubArray[2]}/>
                            </div>
                            <div className="col-sm-3">

                            </div>
                        </div>
                    )
                } else if (cityInfoSubArray.length === 2){
                    rows.push(
                        <div className={rowClassName}>
                            <div className="col-sm-3">
                                <CitySelectionBtn changeCityInfo={this.changeCityInfo}
                                                  currCityInfo={this.props.currCityInfo}
                                                  info={cityInfoSubArray[0]}/>
                            </div>
                            <div className="col-sm-3">
                                <CitySelectionBtn changeCityInfo={this.changeCityInfo}
                                                  currCityInfo={this.props.currCityInfo}
                                                  info={cityInfoSubArray[1]}/>
                            </div>
                            <div className="col-sm-3">

                            </div>
                            <div className="col-sm-3">

                            </div>
                        </div>
                    )
                } else if (cityInfoSubArray.length === 1){
                    rows.push(
                        <div className={rowClassName}>
                            <div className="col-sm-3">
                                <CitySelectionBtn changeCityInfo={this.changeCityInfo}
                                                  currCityInfo={this.props.currCityInfo}
                                                  info={cityInfoSubArray[0]}/>
                            </div>
                            <div className="col-sm-3">

                            </div>
                            <div className="col-sm-3">

                            </div>
                            <div className="col-sm-3">

                            </div>
                        </div>
                    )
                }
            }

            return(
                <div>
                    {rows}
                </div>
            )
        }
    }
}

export default CitySelectionToolbar