import React, {Component} from 'react'
import {cuisineEmojiMap} from "../cuisineEmojiMap";

class CuisineTag extends Component {

    render() {
        let newCuisineEmojiMap = cuisineEmojiMap;

        if (this.props.emojiMap !== null){
            newCuisineEmojiMap = this.props.emojiMap
        }

        let icon = <i className="fa fa-cutlery" />;

        if (this.props.name.toLowerCase().trim() in newCuisineEmojiMap){
            icon = <span>{newCuisineEmojiMap[this.props.name.toLowerCase()]}</span>
        }

        return (
            <span className="cuisineTag">{icon} {this.props.name}</span>
        )
    }
}

export default CuisineTag