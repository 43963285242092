import React, {Component} from 'react'

class CitySelectionBtn extends Component {
    getBtnStyle = (city) => {
        let btnStyle = {
            backgroundColor: "white",
            borderRadius: "15px",
            margin: "5% 20% 5% 20%",
            color: "black",
            cursor: "pointer",
            textAlign: "center",
            padding: "3%"
        }

        if (this.props.currCityInfo !== null){
            if (city === this.props.currCityInfo['name']){
                btnStyle['boxShadow'] = "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                btnStyle['backgroundColor'] = '#0081ff'
                btnStyle['color'] = 'white'
            }
        }

        return btnStyle
    }

    changeCityInfo = (e) => this.props.changeCityInfo(this.props.info)

    render() {
        return (
            <div style={this.getBtnStyle(this.props.info['name'])} className="cityBtn" onClick={this.changeCityInfo}>
                <img src={this.props.info['pic']}
                     style={{width: "30%", height: "30%", borderRadius: "50%"}}
                     alt={this.props.info['name'] + "Logo"} />
                <h4>{this.props.info['name']}</h4>
            </div>
        )
    }
}

export default CitySelectionBtn