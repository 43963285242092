import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

export class GoogleMaps extends Component {
    onMarkerClick = (props, marker, e) => {
        this.props.updateActiveMarker(marker);
        const restaurants = this.props.restaurants;
        let foundRestaurant = [];

        for (let i=0; i < restaurants.length; i++){
            if (marker.position.lat() === restaurants[i]['Latitude'] && marker.position.lng() === restaurants[i]['Longitude']){
                foundRestaurant.push(restaurants[i])
            }
        }

        this.props.updateRestaurants(foundRestaurant)
    }

    render() {
        const mapStyles = {
            width: '100%',
            height: window.innerWidth < 500 ? (window.innerHeight / 3).toString() + "px" : "100%",
            position: 'relative'
        };

        const restaurants = this.props.restaurants;

        let markers = [];

        for (let i=0; i<restaurants.length; i++){
            let current = false;

            if ('position' in this.props.activeMarker){
                if (this.props.activeMarker.position.lat() === restaurants[i]['Latitude'] && this.props.activeMarker.position.lng() === restaurants[i]['Longitude']){
                    current = true;
                }
            }

            if (current){
                markers.push(<Marker
                    icon={{url: "https://project-static-assets.s3.amazonaws.com/Jiklu/map-icon-01.png"}}
                    onClick={this.onMarkerClick}
                    name={restaurants[i]['Name']}
                    position={{lat: restaurants[i]['Latitude'], lng: restaurants[i]['Longitude']}}
                    key={i}
                />)
            } else{
                markers.push(<Marker
                    onClick={this.onMarkerClick}
                    name={restaurants[i]['Name']}
                    position={{lat: restaurants[i]['Latitude'], lng: restaurants[i]['Longitude']}}
                    key={i}
                />)
            }
        }

        return (
            <div style={{height: mapStyles.height === "100%" ? "600px" : mapStyles.height, width: "100%"}}>
                <Map
                    containerStyle={mapStyles}
                    google={this.props.google}
                    zoom={this.props.cityInfo['zoom']}
                    style={mapStyles}
                    initialCenter={
                        {
                            lat: this.props.cityInfo['lat'],
                            lng: this.props.cityInfo['lng']
                        }
                    }
                >
                    {markers}
                </Map>
            </div>
        );
    }
}

export default GoogleApiWrapper({apiKey: "AIzaSyAHju8Yppq0fxO-YsrsDuOpAZ0IfyGKprw"})(GoogleMaps);