import React, {Component} from 'react'

class SearchBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchInput: ""
        }
    }

    handleInputChange = (e) => {
        const searchVal = e.target.value.trim().toLowerCase();

        this.setState({
            searchInput: e.target.value
        })

        let updatedRestaurants;
        if (searchVal !== "")
        {
            updatedRestaurants = [];

            for (let i=0; i<this.props.allRestaurants.length; i++){
                const info = this.props.allRestaurants[i];
                if (info['Name'].toLowerCase().includes(searchVal) || info['Cuisine'].toLowerCase().includes(searchVal) || info['Dishes'].toLowerCase().includes(searchVal)){
                    updatedRestaurants.push(info)
                }
            }
        } else {
            updatedRestaurants = this.props.allRestaurants;
        }

        this.props.updateRestaurants(updatedRestaurants)

    }

    clearSearch = (e) => {
        this.props.updateRestaurants(this.props.allRestaurants)
        this.setState({
            searchInput: ""
        })
    }

    render() {
        return (
            <div className="row">
                <div className="col-sm-11">
                    <input
                        onChange={this.handleInputChange}
                        style={{padding: "10px", width: "100%"}}
                        value={this.state.searchInput}
                        placeholder="Search Name, Cuisine, or Dishes to Filter"
                    />
                </div>
                <div className="col-sm-1">
                    {this.state.searchInput.trim() !== "" ? <button style={{borderRadius: "25%", padding: "5px"}} onClick={this.clearSearch}><i className="fa fa-undo" aria-hidden="true" /> </button> : null}
                </div>
            </div>
        )
    }
}

export default SearchBar