import React, {Component} from 'react'

class FoundFromList extends Component {
    render() {
        const foundFromArray = this.props.foundFrom.split("|")

        return (
            <div>
                <h6><img src={"https://s2.googleusercontent.com/s2/favicons?domain_url=" + this.props.url} alt="Icon"/> <b>{foundFromArray[0]}</b>, <a href={this.props.url} target="_blank" rel="noopener noreferrer">{foundFromArray[1]}</a></h6>
            </div>
        )
    }
}

export default FoundFromList