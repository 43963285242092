import React, {Component} from 'react'

class RestaurantURL extends Component {
    render() {
        return (
            <a href={this.props.url} target="_blank" rel="noopener noreferrer" className="restaurantURL">
                <span style={{color: "#0081ff"}}><i className="fa fa-home" aria-hidden="true" /></span> Restaurant Website
            </a>
        )
    }
}

export default RestaurantURL