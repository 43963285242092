import React, {Component} from 'react'

class ErrorRestaurants extends Component {
    render() {
        return (
            <div style={{textAlign: "center", marginTop: "2%"}}>
                <h2>There was an error getting the restaurants :( Please try again.</h2>
            </div>
        )
    }
}

export default ErrorRestaurants