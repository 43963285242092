export const cuisineEmojiMap = {
    malaysian: "🇲🇾",
    italian: "🇮🇹",
    pizza: "🍕",
    chinese: "🇨🇳",
    korean: "🇰🇷",
    snacks: "🥨",
    "street food": "🌭",
    jewish: "✡️",
    bagel: "🥯",
    thai: "🇹🇭",
    american: "🇺🇸",
    noodles: "🍜",
    indian: "🇮🇳",
    mexican: "🇲🇽",
    british: "🇬🇧",
    japanese: "🇯🇵",
    ramen: "🍜",
    albanian: "🇦🇱",
    ukranian: "🇺🇦",
    bar: "🍸",
    beer: "🍺",
    moroccan: ""
}